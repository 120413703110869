var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import DataBox from '~/components/common/data-box.vue';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import { Prop, Watch } from 'vue-property-decorator';
import { Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import { CaseFollowRecordService } from '~/services/business-service/case-follow-record.service';
import ViewRecoredFiles from "~/components/collection-manage/execution/follow-query-base/view-recored-files.vue";
var collectionManageModule = namespace('collection-manage');
var FollowAllQueryBatch = /** @class */ (function (_super) {
    __extends(FollowAllQueryBatch, _super);
    function FollowAllQueryBatch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.followQuery = {
            certificateNo: '',
            collectionWay: 0,
            principalId: '',
        };
        _this.loading = { state: false };
        _this.dialog = {
            repeat: false,
            recordFiles: false
        };
        _this.current = {};
        _this.currentId = "";
        _this.recordModel = {
            content: '',
            operateTime: null,
            informationUpdate: '',
            caseType: "",
            caseId: ""
        };
        _this.approvalRules = {
            content: [
                { required: true, message: "请输入内容", trigger: "blur" }
            ]
        };
        return _this;
    }
    /**
     * 调解执行页面使用
     */
    FollowAllQueryBatch.prototype.onRecordCountChange = function () {
        this.refreshData();
    };
    FollowAllQueryBatch.prototype.collectionDelete = function (id) {
        var _this = this;
        this.$confirm("是否确认删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            var model = {
                id: id,
                caseType: _this.otherData.caseType
            };
            _this.caseFollowRecordService.deleteCaseFollowRecord(model)
                .subscribe(function (data) {
                _this.$message.success("删除成功!");
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    FollowAllQueryBatch.prototype.onCaseIdChange = function (val) {
        val && this.refreshData();
    };
    FollowAllQueryBatch.prototype.refreshData = function () {
        var _this = this;
        this.followQuery.certificateNo = this.certificateNo;
        this.followQuery.principalId = this.principalId;
        this.loading.state = true;
        this.caseFollowRecordService.getAllCaseFollowRecord(this.followQuery, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        }, function (err) { return _this.$message.error(err.msg); });
    };
    FollowAllQueryBatch.prototype.collectionUpdate = function (row) {
        this.currentId = row.id;
        this.recordModel.content = row.content;
        this.recordModel.operatorTime = row.operatorTime;
        this.recordModel.informationUpdate = row.informationUpdate;
        this.recordModel.caseId = this.currentCase.id;
        this.recordModel.caseType = this.otherData.caseType;
        this.dialog.repeat = true;
    };
    FollowAllQueryBatch.prototype.cancel = function () {
        this.dialog.repeat = false;
        var form = this.$refs['approval-form'];
        form.resetFields();
    };
    FollowAllQueryBatch.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['approval-form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.recordModel = Object.assign({}, _this.recordModel, { id: _this.currentId });
            _this.caseFollowRecordService.updateCaseFollowRecord(_this.recordModel)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.cancel();
                _this.refreshData();
            }, function (err) { return _this.$message.error(err.msg); });
        });
    };
    FollowAllQueryBatch.prototype.viewRecordFiles = function (row) {
        this.current = row;
        this.dialog.recordFiles = true;
    };
    __decorate([
        Dependencies(PageService)
    ], FollowAllQueryBatch.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], FollowAllQueryBatch.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FollowAllQueryBatch.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowAllQueryBatch.prototype, "recordCount", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowAllQueryBatch.prototype, "otherData", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowAllQueryBatch.prototype, "currentCase", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], FollowAllQueryBatch.prototype, "certificateNo", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], FollowAllQueryBatch.prototype, "principalId", void 0);
    __decorate([
        Watch("recordCount")
    ], FollowAllQueryBatch.prototype, "onRecordCountChange", null);
    __decorate([
        Watch("certificateNo", { immediate: true })
    ], FollowAllQueryBatch.prototype, "onCaseIdChange", null);
    FollowAllQueryBatch = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                ViewRecoredFiles: ViewRecoredFiles
            },
        })
    ], FollowAllQueryBatch);
    return FollowAllQueryBatch;
}(Vue));
export default FollowAllQueryBatch;
