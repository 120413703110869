import { render, staticRenderFns } from "./follow-all-query-batch.vue?vue&type=template&id=5c13615b"
import script from "./follow-all-query-batch.vue?vue&type=script&lang=ts"
export * from "./follow-all-query-batch.vue?vue&type=script&lang=ts"
import style0 from "./follow-all-query-batch.vue?vue&type=style&index=0&id=5c13615b&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports